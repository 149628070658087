import React from "react"
import EmbedSlidesPresentation from "../../components/EmbedSlidesPresentation/EmbedSlidesPresentation"
import SlidesPresentation from "../../templates/slides-presentation"

const StaffingIndustryEng = (): React.ReactElement => {
  return (
    <SlidesPresentation>
      <EmbedSlidesPresentation iframeSrc="https://slides.com/bramtierie/flipbase-asynchronous-video-interaction-staffing-industry-eng-94b398/embed" />
    </SlidesPresentation>
  )
}

export default StaffingIndustryEng
